import { EDeviceType, EOwnershipStatus } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { IMachineListQueryElement, IMachineListElement } from './types';

export const processMachineList = (machineList: IMachineListQueryElement[]) => {
  const processedMachineList = machineList.reduce(
    (processedMachines: IMachineListElement[], machine: IMachineListQueryElement): IMachineListElement[] => {
      // Filter unnecessary machines
      if (machine.ownershipStatus === EOwnershipStatus.REJECTED) {
        return processedMachines;
      }
      const isImplement = machine?.type === EDeviceType.IMPLEMENT;
      // Process data to machine list
      const model = machine.model?.name || machine.modelText || '';
      return [
        ...processedMachines,
        {
          ...machine,
          machineIcon: isImplement ? 'implement' : 'machines-outline',
          name:
            machine.name ??
            (machine.brand?.name || machine?.brandText
              ? `${machine.brand?.name || machine?.brandText} ${model}`
              : model),
          image:
            machine?.images?.thumbnails?.SIZE_48_48 ||
            machine?.images?.thumbnails?.SIZE_140_105 ||
            machine?.images?.url ||
            machine?.model?.images?.thumbnails?.SIZE_48_48 ||
            machine?.modelVariant?.images?.thumbnails?.SIZE_48_48,
          brand: (machine.brand?.name || machine?.brandText) ?? '',
          model,
        },
      ];
    },
    [],
  );
  // Sort processed machine list by createdDateTime string in descending order
  processedMachineList.sort(
    (a, b) => new Date(b.createdDateTime || '').getTime() - new Date(a.createdDateTime || '').getTime(),
  );
  // Return sorted machine list
  return processedMachineList;
};
