import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import loadable from '@loadable/component';
import {
  addModuleRouter,
  Footer,
  LoadingBar,
  MainContainer,
  PathChangeListener,
  Snackbar,
} from '@agco-onedigital/ecosystem-lib-web-components';
import { commonMessages, EAuthRoutes, FSEAuthRoutes } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { handleLogoutRedirect } from '../../utils/msalConfiguration/utils';
import { useLaunchDarkly } from '../../store/LaunchDarkly';
import { useUser } from '../../store/User';
import ScrollToTop from '../../components/ScrollToTop';
import PageNotFound from '../../components/PageNotFound';
import FSEModuleLoader from '../../components/ModuleLoader/FSEModuleLoader';
import InactivityModal from '../../components/InactivityModal';

import Sidebar from '../Sidebar';

import AuthRouter from './AuthRouter';
import Authenticated from './Authenticated';
import { EPreAuthRoutes } from './types';
import { AppContainer, ContentWrapper } from './styles';
import LoginError from './LoginError';

const generalMessages = commonMessages.general;
export const messages = commonMessages.authTemplates.footer;

const Registration = loadable(() => import('../../pages/Registration'), {
  fallback: <LoadingBar />,
});
const Dashboard = loadable(() => import('../../pages/Dashboard'), {
  fallback: <LoadingBar />,
});
const LogInAndSecurity = loadable(() => import('../../pages/User/LogInAndSecurity'), {
  fallback: <LoadingBar />,
});
const PersonalData = loadable(() => import('../../pages/User/PersonalData'), {
  fallback: <LoadingBar />,
});
const DataAndPrivacy = loadable(() => import('../../pages/User/DataAndPrivacy'), {
  fallback: <LoadingBar />,
});
const Fleet = loadable(() => import('../../pages/Fleet'), {
  fallback: <LoadingBar />,
});
const FleetDetails = loadable(() => import('../../pages/FleetDetails'), {
  fallback: <LoadingBar />,
});
const AddMachine = loadable(() => import('../../pages/AddMachine'), {
  fallback: <LoadingBar />,
});
const VerifyMachine = loadable(() => import('../../pages/VerifyMachine'), {
  fallback: <LoadingBar />,
});
const ClaimBusiness = loadable(() => import('../../pages/ClaimBusiness'), {
  fallback: <LoadingBar />,
});
const SmartFarming = loadable(() => import('../../pages/SmartFarming'), {
  fallback: <LoadingBar />,
});
const SettingsBusinessAccounts = loadable(() => import('../../pages/Settings/BusinessAccounts'), {
  fallback: <LoadingBar />,
});

const authenticatedRoutes = [
  {
    path: EAuthRoutes.DASHBOARD,
    component: Dashboard,
  },
  {
    path: `${EAuthRoutes.DASHBOARD}${EAuthRoutes.CLAIM_BUSINESS}`,
    component: ClaimBusiness,
  },
  {
    path: `${EAuthRoutes.USER}${EAuthRoutes.LOGIN_AND_SECURITY}`,
    component: LogInAndSecurity,
  },
  {
    path: `${EAuthRoutes.USER}${EAuthRoutes.PERSONAL_DATA}`,
    component: PersonalData,
  },
  {
    path: `${EAuthRoutes.SETTINGS}${EAuthRoutes.PROFILE}`,
    component: PersonalData,
  },
  {
    path: `${EAuthRoutes.USER}${EAuthRoutes.DATA_AND_PRIVACY}`,
    component: DataAndPrivacy,
  },
  {
    path: EAuthRoutes.SETTINGS_BUSINESS_ACCOUNTS,
    component: SettingsBusinessAccounts,
  },
  {
    path: EAuthRoutes.USER_BUSINESS_ACCOUNTS,
    component: SettingsBusinessAccounts,
  },
  {
    path: `${EAuthRoutes.LEGACY_FLEET_LIST}`,
    component: Fleet,
  },
  {
    path: `${EAuthRoutes.LEGACY_FLEET_LIST}/:id/*`,
    component: FleetDetails,
  },
  {
    path: `${EAuthRoutes.FLEET_OVERVIEW}`,
    component: Fleet,
  },
  {
    path: `${EAuthRoutes.FLEET_OVERVIEW}/:id/*`,
    component: FleetDetails,
  },
  {
    path: `${EAuthRoutes.SERVICE_AND_INFORMATION}${EAuthRoutes.ADD_MACHINE}`,
    component: AddMachine,
  },
  {
    path: `${EAuthRoutes.SERVICE_AND_INFORMATION}${EAuthRoutes.VERIFY_MACHINE}`,
    component: VerifyMachine,
  },
  {
    path: `${EAuthRoutes.SMART_FARMING}`,
    component: SmartFarming,
  },
];

export const EmptyComponent = () => null;

const fseRoutes = [
  ...Object.values(FSEAuthRoutes).map(path => ({
    path: `${path}/*`,
    component: EmptyComponent,
  })),
];

const fallbackRoute = {
  path: '*',
  component: PageNotFound,
};

export const router = createBrowserRouter([
  {
    path: EPreAuthRoutes.REGISTRATION,
    element: <Registration />,
  },
  {
    path: EPreAuthRoutes.HOME,
    element: <AuthRouter />,
  },
  {
    path: EPreAuthRoutes.LOGIN_ERROR,
    element: <LoginError />,
  },
  ...[...authenticatedRoutes, ...fseRoutes, fallbackRoute].map(({ path, component: Component }) => ({
    path,
    element: (
      <Authenticated>
        <Sidebar />
        <ScrollToTop />
        <PathChangeListener />
        <InactivityModal />
        <MainContainer>
          <ContentWrapper>
            <FSEModuleLoader />
            <Component />
          </ContentWrapper>
          <Footer messages={messages} />
        </MainContainer>
      </Authenticated>
    ),
  })),
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

addModuleRouter(router);

const AppNavigation = () => {
  const launchDarkly = useLaunchDarkly();
  const user = useUser();
  useEffect(() => {
    if (user.isFailedToLoad && !user.isLoading) {
      handleLogoutRedirect(launchDarkly, false, true);
    }
  }, [user.isFailedToLoad, user.isLoading, launchDarkly]);

  return (
    <AppContainer>
      <RouterProvider router={router} />
    </AppContainer>
  );
};

export default AppNavigation;
