import { Logo } from '@agco-onedigital/ecosystem-lib-web-components';
import styled from 'styled-components';

export const ContentReWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 100px;
  padding-left: 30px;
`;

export const StyledLogo = styled(Logo)`
  width: 180px;
  padding-bottom: 30px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
