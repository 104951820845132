import { AuthenticationResult } from '@azure/msal-browser';
import { useEffect, useState } from 'react';

import { EPreAuthRoutes } from '../../containers/Router/types';
import { TLaunchDarklySet, useLaunchDarkly } from '../../store/LaunchDarkly';

import { getChangePasswordRequest, getLoginRequest } from './msalConfig';
import getMsalInstance from './msalInstance';

export const handleLoginRedirect = async (launchDarkly: TLaunchDarklySet) =>
  getMsalInstance(launchDarkly).loginRedirect(getLoginRequest(launchDarkly));

export const handleChangePasswordRedirect = (launchDarkly: TLaunchDarklySet) => {
  const launchDarklyForChangePassword: TLaunchDarklySet = JSON.parse(JSON.stringify(launchDarkly));
  if (!launchDarklyForChangePassword['config-web-app']?.microsoft?.azureActiveDirectory) {
    return;
  }
  launchDarklyForChangePassword['config-web-app'].microsoft.azureActiveDirectory.authority =
    launchDarklyForChangePassword['config-web-app'].microsoft.azureActiveDirectory.changePasswordAuthority;

  const trimmedPath = window.location.pathname.slice(1);
  launchDarklyForChangePassword['config-web-app'].microsoft.azureActiveDirectory.redirectUrl += trimmedPath;
  getMsalInstance(launchDarklyForChangePassword, true).loginRedirect(
    getChangePasswordRequest(launchDarklyForChangePassword),
  );
};

export const handleLogoutRedirect = async (
  launchDarkly: TLaunchDarklySet,
  isInactivityLogout?: boolean,
  isUserErrorLogout?: boolean,
) => {
  let postLogoutRedirectUri: string = EPreAuthRoutes.HOME;
  localStorage.removeItem('openSubmenu');
  if (isInactivityLogout) {
    localStorage.setItem('inactivityLogout', '1');
    postLogoutRedirectUri = `${EPreAuthRoutes.HOME}?inactivityLogout=1`;
  } else {
    localStorage.removeItem('inactivityLogout');
  }
  if (isUserErrorLogout) {
    postLogoutRedirectUri = `${EPreAuthRoutes.LOGIN_ERROR}`;
  }

  const msalInstance = getMsalInstance(launchDarkly);
  return msalInstance.logoutRedirect({
    postLogoutRedirectUri,
  });
};

export const getAuthDataLD = async (launchDarkly: TLaunchDarklySet) => {
  const msalInstance = getMsalInstance(launchDarkly);
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  const request = {
    ...getLoginRequest(launchDarkly),
    account: msalInstance.getAllAccounts()[0],
  };
  return msalInstance.acquireTokenSilent(request);
};

window.SHARED_MODULE_DATA.getAuthData = () => {
  const launchDarkly = window.SHARED_MODULE_DATA.launchDarklySet;
  if (!launchDarkly) {
    throw new Error('LaunchDarkly not set');
  }
  return getAuthDataLD(launchDarkly);
};

export const useAuthDetails = () => {
  const launchDarkly = useLaunchDarkly();
  const [result, setResult] = useState<AuthenticationResult | null>(null);
  const init = async () => {
    const authDetails = await getAuthDataLD(launchDarkly);
    setResult(authDetails);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return result;
};
