import React from 'react';

import { Footer, Link, Typography } from '@agco-onedigital/ecosystem-lib-web-components';

import { EPreAuthRoutes } from './types';
import { messages } from './Router';
import { ContentWrapper } from './styles';
import { ContentReWrapper, StyledLogo, Wrapper } from './LoginError.styles';

const LoginError = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <ContentReWrapper>
          <StyledLogo brand="agco" variant="primary" mode="light" />
          <Typography.Body size="lg" style={{ fontWeight: 'bold' }}>
            We can&apos;t sign you in!
          </Typography.Body>
          <Typography.Body size="lg">We&apos;re sorry, but we couldn&apos;t log you in.</Typography.Body>
          <Typography.Body size="lg">
            Please check your email and password and try again. <br /> Please click
            <Link variant="primary" size="lg" href={EPreAuthRoutes.HOME}>
              here
            </Link>
            to go back to the login page.
          </Typography.Body>
        </ContentReWrapper>
      </ContentWrapper>
      <Footer messages={messages} />
    </Wrapper>
  );
};

export default LoginError;
