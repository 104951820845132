import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeviceAvailability } from '../AddMachine';
import { verifyVinDeviceAvailabilityThunk } from './thunks/verifyVinDeviceAvailabilityThunk';
import { IVerifyMachineState, VerifyMachineReducers } from './types';

const initialState: IVerifyMachineState = {
  data: {},
  isVerifyModalOpened: false,
};

export const verifyMachineSlice = createSlice<IVerifyMachineState, VerifyMachineReducers, 'VerifyMachine', any>({
  name: 'VerifyMachine',
  initialState,
  reducers: {
    initVerifyMachine: (state: IVerifyMachineState) => {
      state.isLoading = false;
      state.vinError = undefined;
      state.data.deviceAvailability = undefined;
    },
    resetVerifyMachine: () => initialState,
    setVerifiedModalOpened: (state, action: PayloadAction<boolean>) => {
      state.isVerifyModalOpened = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(verifyVinDeviceAvailabilityThunk.pending, state => {
      state.isLoading = true;
      state.vinError = undefined;
      state.data.deviceAvailability = undefined;
    });
    builder.addCase(
      verifyVinDeviceAvailabilityThunk.fulfilled,
      (state, action: PayloadAction<IDeviceAvailability | undefined>) => {
        state.isLoading = false;
        if (!action.payload || action.payload.status === 'UNAVAILABLE') {
          state.vinError = 'not-found';
        } else if (action.payload.status === 'AVAILABLE') {
          state.data.deviceAvailability = action.payload;
          state.vinToSave = action.payload.vin;
        } else if (action.payload.status === 'ALREADY_IN_ACCOUNT') {
          state.vinError = 'already-in-account';
        }
      },
    );
    builder.addCase(verifyVinDeviceAvailabilityThunk.rejected, state => {
      state.isLoading = false;
      state.vinError = 'service-error';
    });
  },
});

export const { resetVerifyMachine, initVerifyMachine, setVerifiedModalOpened } = verifyMachineSlice.actions;
const verifyMachineReducer = verifyMachineSlice.reducer;

export default verifyMachineReducer;
