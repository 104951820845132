import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { DeviceImageUploadRequest, DeviceImageUploadResponse, TQueryResponse } from '../../../types';
import { client } from '../../graphql';

export const uploadDeviceImage = async (
  launchDarkly: TLaunchDarklySet,
  deviceImageUploadRequest: DeviceImageUploadRequest,
): Promise<DeviceImageUploadResponse> => {
  const mutation = gql`
    mutation uploadDeviceImage($deviceImageUploadRequest: DeviceImageUploadRequest!) {
      uploadDeviceImage(deviceImageUploadRequest: $deviceImageUploadRequest) {
        images {
          url
          thumbnails {
            SIZE_319_319
            SIZE_48_48
            SIZE_140_100
          }
        }
      }
    }
  `;

  const result = await client(launchDarkly).mutate<
    TQueryResponse<'uploadDeviceImage', DeviceImageUploadResponse>,
    { deviceImageUploadRequest: DeviceImageUploadRequest }
  >({
    mutation,
    variables: {
      deviceImageUploadRequest,
    },
  });

  if (!result.data) {
    throw new Error('No data returned from server');
  }

  return result.data.uploadDeviceImage;
};
