import { gql } from '@apollo/client';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { IMachineListQueryElement } from '../../../../store/MachineList';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getMachineList = async (launchDarkly: TLaunchDarklySet, accountId: string) => {
  const imagesEnabled = launchDarkly['machine-image-upload'];
  const { data } = await client(launchDarkly).query<TQueryResponse<'fleetDevices', IMachineListQueryElement[]>>({
    query: gql`
      query {
        fleetDevices (filter: { accountId: "${accountId}" }) {
          vin
          id
          type
          brand {
            name
          }
          model {
            name
          }
          subCategoryText
          categoryText
          brandText
          modelText
          name
          ownershipStatus
          image
          modelVariant {
            name
            id
            ${
              imagesEnabled
                ? `images {
              url
              thumbnails {
                SIZE_319_319
                SIZE_48_48
                SIZE_140_100
              }
            }`
                : ''
            }
          }
          ${
            imagesEnabled
              ? `images {
            url
            thumbnails {
              SIZE_319_319
              SIZE_48_48
              SIZE_140_100
            }
          }`
              : ''
          }
          createdDateTime
          model {
            name
            id
            modelSeries {
              name
            }
            ${
              imagesEnabled
                ? `images {
              url
              thumbnails {
                SIZE_319_319
                SIZE_48_48
                SIZE_140_100
              }
            }`
                : ''
            }
          }
        }
      }
    `,
  });
  return data.fleetDevices;
};
