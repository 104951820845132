import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadDeviceImage } from '../../../service/graphql/machine/uploadDeviceImage';
import { AsyncThunkConfig } from '../../store';
import { getMachineThunk } from './getMachineThunk';
import { DeviceImageUploadRequest, DeviceImageUploadResponse } from '../../../service/types';

export const uploadDeviceImageThunk = createAsyncThunk<
  DeviceImageUploadResponse,
  { deviceImageUploadRequest: DeviceImageUploadRequest },
  AsyncThunkConfig
>('machineDetails/uploadDeviceImage', async ({ deviceImageUploadRequest }, { getState, dispatch }) => {
  const {
    launchDarkly,
    selectedAccount: { activeAccountId },
  } = getState();

  if (!activeAccountId) {
    throw new Error('No accounts or user found.');
  }

  const result = await uploadDeviceImage(launchDarkly.data, deviceImageUploadRequest);

  await dispatch(getMachineThunk(deviceImageUploadRequest.deviceId));

  return result;
});
