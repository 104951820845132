import { createSlice } from '@reduxjs/toolkit';
import { commonMessages } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { Snackbar } from '@agco-onedigital/ecosystem-lib-web-components';

import { getMachineThunk, deleteMachineThunk, editMachineThunk } from './thunks';
import { IMachineDetailsState } from './types';
import { uploadDeviceImageThunk } from './thunks/uploadDeviceImageThunk';
import { DeviceImageUploadResponse } from '../../service/types';
import { deleteDeviceImageThunk } from './thunks/deleteDeviceImageThunk';

const messages = commonMessages.general;

const initialState: IMachineDetailsState & { uploadImage?: DeviceImageUploadResponse } = {};

const machineDetailsSlice = createSlice({
  name: 'machineDetails',
  initialState,
  reducers: {
    resetMachine: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getMachineThunk.rejected, state => {
      state.isFailedToLoad = true;
      Snackbar.showSnack({
        title: messages.apiError,
        buttonTitle: messages.reload,
        type: 'error',
        onButtonClick: () => window.location.reload(),
      });
    });
    builder.addCase(getMachineThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getMachineThunk.pending, state => {
      state.isFailedToLoad = false;
    });

    builder.addCase(deleteMachineThunk.rejected, state => {
      Snackbar.showSnack({
        title: messages.apiError,
        buttonTitle: messages.reload,
        type: 'error',
        onButtonClick: () => window.location.reload(),
      });
    });
    builder.addCase(deleteMachineThunk.fulfilled, (state, action) => {
      state.data = undefined;
    });
    builder.addCase(deleteMachineThunk.pending, state => {
      state.isFailedToLoad = false;
    });

    builder.addCase(editMachineThunk.rejected, state => {
      Snackbar.showSnack({
        title: messages.apiError,
        buttonTitle: messages.reload,
        type: 'error',
        onButtonClick: () => window.location.reload(),
      });
    });
    builder.addCase(editMachineThunk.fulfilled, (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    });
    builder.addCase(editMachineThunk.pending, state => {
      state.isFailedToLoad = false;
    });

    builder.addCase(uploadDeviceImageThunk.rejected, state => {
      state.isImageUpdating = false;
      Snackbar.showSnack({
        title: messages.apiError,
        buttonTitle: messages.reload,
        type: 'error',
        onButtonClick: () => window.location.reload(),
      });
    });
    builder.addCase(uploadDeviceImageThunk.fulfilled, (state, action) => {
      state.uploadImage = action.payload;
      state.isImageUpdating = false;
    });
    builder.addCase(uploadDeviceImageThunk.pending, state => {
      state.isImageUpdating = true;
      state.isFailedToLoad = false;
    });
    builder.addCase(deleteDeviceImageThunk.fulfilled, (state, action) => {
      state.uploadImage = action.payload;
    });
    builder.addCase(deleteDeviceImageThunk.pending, state => {
      state.isFailedToLoad = false;
    });
  },
});

export const { resetMachine } = machineDetailsSlice.actions;

const machineDetailsReducer = machineDetailsSlice.reducer;

export default machineDetailsReducer;
