import { LDContext } from 'launchdarkly-js-client-sdk';

const LDClient = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID ?? '';

export const getHostName = () => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_NODE_ENV === 'local') {
      return `localhost/${process.env.REACT_APP_NAME}/local`;
    }
    if (process.env.REACT_APP_NODE_ENV === 'local-stage') {
      return `localhost/${process.env.REACT_APP_NAME}/local-stage`;
    }
    return `localhost/${process.env.REACT_APP_NAME}`;
  }
  return window.location.hostname;
};

export const CONTEXT: LDContext = {
  key: 'anonymous',
  custom: {
    hostName: getHostName(),
  },
};

export const LAUNCHDARKLY_CLIENT_ID = LDClient;
export const LAUNCHDARKLY_APP_TYPE = 'web';

export const LDCustomLogger = {
  debug() {},
  info() {},
  warn(message: string) {
    console.warn(message);
  },
  error(message: string) {
    console.error(message);
  },
};
