import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteDeviceImage } from '../../../service/graphql/machine/deleteDeviceImage';
import { AsyncThunkConfig } from '../../store';
import { getMachineThunk } from './getMachineThunk';
import { DeviceImageUploadResponse } from '../../../service/types';

export const deleteDeviceImageThunk = createAsyncThunk<
  DeviceImageUploadResponse,
  { fleetDeviceId: string },
  AsyncThunkConfig
>('machineDetails/deleteDeviceImage', async ({ fleetDeviceId }, { getState, dispatch }) => {
  const {
    launchDarkly,
    selectedAccount: { activeAccountId },
  } = getState();

  if (!activeAccountId) {
    throw new Error('No accounts or user found.');
  }

  const result = await deleteDeviceImage(launchDarkly.data, fleetDeviceId);

  await dispatch(getMachineThunk(fleetDeviceId));

  return result;
});
