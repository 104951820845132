import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { DeviceImageUploadResponse, TQueryResponse } from '../../../types';
import { client } from '../../graphql';

export const deleteDeviceImage = async (
  launchDarkly: TLaunchDarklySet,
  fleetDeviceId: string,
): Promise<DeviceImageUploadResponse> => {
  const mutation = gql`
    mutation deleteDeviceImage($fleetDeviceId: ID!) {
      deleteDeviceImage(fleetDeviceId: $fleetDeviceId) {
        images {
          url
          thumbnails {
            SIZE_319_319
            SIZE_48_48
            SIZE_140_100
          }
        }
      }
    }
  `;

  const result = await client(launchDarkly).mutate<
    TQueryResponse<'deleteDeviceImage', DeviceImageUploadResponse>,
    { fleetDeviceId: string }
  >({
    mutation,
    variables: {
      fleetDeviceId,
    },
  });

  if (!result.data) {
    throw new Error('No data returned from server');
  }

  return result.data.deleteDeviceImage;
};
